/* фон страниц */
$g_color_pageBackground: #f7f8f8;

/* фон левой панели в интерфейсе НПА */
$g_color_leftSidebarBackground: #455f71;

/* если текущий элемент ушел внутрь, это цвет родительского над текущим  */
$parentOverCurrentColor: #698496;

/* фон неактивных элементов */
$disableBackgroundColor: #4f6a7c;

/* фон панели юзера */
$userPanelColor: #4f6a7c;

/* верхняя узкая шапка сайта */
$g_color_headerBar: $g_color_leftSidebarBackground;

/* фон попапов */
$g_color_popupBackground: #2d4452;

/* светлая панель с текстом на попапе  */
$textPanelColorOnPopup: #354e5e;

/* цвет текущих элементов и основных элементов управления */
$mainBlueColor: #0070a4;

/* цвет текущих элементов и основных элементов управления */
$mainBlackColor: #333333;

/* цвет кнопки перенаправить */
$blueColorRedirect: #4498d0;

/* цвет текущих элементов и основных элементов управления (шапка + сайд бар) */
$headerBlueColor: #008bcb;

/* основной темно синий цвет (шапка) подсветка в сайдбаре меню (новый) */
$headerDarkBlueColor: #0b6c97;

/* фон активных элементов */
$g_color_backgroundActiveItem: #0070a4;

/* ссылка на светлом фоне */
$urlLinkColorOnLightTheme: #0070a4;

/* цвет выделенных элементов в селектах */
$selectItemHover: #0070a4;

/* алерты */
$alertColor: #eb3f3c;

/* предупреждения */
$attentionColor: #ffff99;

/* очень важный элемент  */
$importantColor: #eb3f3c;

/* для отображения просроченных или не согласованных элементов */
$corruptColor: #d90024;

/* для отработанных или согласованных элементов */
$approvedColor: #23775c;

/* для новых элементов */
$newColor: #82a700;

/* цвет ползунка в скролле на темном элементе */
$scrollThumbColorOnBlackTheme: #667f8e;

/* цвет ползунка в скролле на светлом элементе */
$scrollThumbColorOnLightTheme: #999999;

/* фон скролл полосы на светлом элементе */
$scrollBgColorOnLightTheme: #dddddd;

/* основной цвет шрифта */
$mainFontColor: #333;

/* цвет чекбоксов на темном фоне */
$colorChekboxBlackTheme: #0587c4;

/* цвет loaderа в темной теме */
$loaderBlackTheme: #7491a5;

/* серый цвет шрифта для лэйблов */
$g_color_grayLabelFont: #666666;

/* кремовый цвет бордюра некоторых элементов на темной теме */
$color_borderOnBlackTheme: #425a68;

/* кремовый цвет некоторых элементов на темной теме */
$color-cream_OnBlackTheme: #4c6779;

/* отступ доступ предоставили */
$grantedAccessGapColor: #3d5766;

$red-danger: #cb0935;

$gray-border: #dce6ec;
$gray-light-border: #d7d7d7;
$gray-text: #dce6ec;
$gray-dark-text-and-background: #4f6a7c;
$gray-light-text: #a5bbcb;
$gray-background-header-documents: #698496;
$gray-font-color: #a5bac7;
$gray-agenda-document-text: #828282;

$bluelight-background-analytics: #d8e1e6;
/* голубой цвет для градиента шапки */
$blue-light-for-gradient: #179dda;
$blue-active-background: #008bcb;
$blue-dark-background-container: #2e4451;
$blue-cerulean: #016fa4;

$yellow-prohibitions: #e0c90e;
$yellow-alert-gold: gold;

$white-text: #fff;

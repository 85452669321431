@import '@angular/cdk/overlay-prebuilt.css';
@import 'proportions/scroll';

/** https://stackoverflow.com/questions/35578083/what-is-the-equivalent-of-ngshow-and-nghide-in-angular-2/35578093#35578093 */
[hidden] {
  display: none !important;
}

.dark-backdrop {
  background: rgba(1, 1, 1, 0.6);
}

.light-backdrop {
  background-color: rgba(255, 255, 255, 0.7);
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: 'Roboto-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen-Sans', 'Ubuntu', 'Cantarell',
    'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  background-color: #f7f8f8;
  overflow: hidden;
  :focus {
    outline: none;
  }
}

.flex-1 {
  flex: 1;
}

.highcharts-credits {
  display: none;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: URL('/assets/fonts/Roboto-Bold.ttf');
  src: URL('/assets/fonts/Roboto-Bold.woff');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: URL('/assets/fonts/Roboto-Regular.ttf');
  src: URL('/assets/fonts/Roboto-Regular.woff');
}
@font-face {
  font-family: 'Roboto-Italic';
  src: URL('/assets/fonts/Roboto-Italic.ttf');
  src: URL('/assets/fonts/Roboto-Italic.woff');
}
@font-face {
  font-family: 'Roboto-Light';
  src: URL('/assets/fonts/Roboto-Light.ttf');
  src: URL('/assets/fonts/Roboto-Light.woff');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: URL('/assets/fonts/Roboto-Medium.ttf');
  src: URL('/assets/fonts/Roboto-Medium.woff');
}
@font-face {
  font-family: 'Roboto-Thin';
  src: URL('/assets/fonts/Roboto-Thin.ttf');
  src: URL('/assets/fonts/Roboto-Thin.woff');
}
@font-face {
  font-family: 'Roboto-Black';
  src: URL('/assets/fonts/Roboto-Black.ttf');
  src: URL('/assets/fonts/Roboto-Black.woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: 'normal';
  font-weight: 400;
  src: URL('/assets/fonts/Roboto-Regular.ttf');
  src: URL('/assets/fonts/Roboto-Regular.woff');
}

.scroll-calendar {
  @extend .scrollbarOnBlackTheme;
}
.scroll-documents {
  overflow: auto;

  @include customScroll($scrollThumbColorOnLightTheme, $scrollBgColorOnLightTheme, $scrollWidth);
}

.scroll-popup,
.scrolled_table {
  overflow: auto;
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #8b8484;
  }
}

.scroll-notifications {
  overflow: auto;
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #666666;
  }
}

.with_animation {
  transition: all 0.4s ease-in-out;
  width: 100%;
}

.collapsed {
  overflow: hidden;
  width: 0;
}

.cdk-overlay-container {
  z-index: 10000;
}

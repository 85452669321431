@import '../colors';

/* ширина скролла */
$scrollWidth: 12px;

/* Если пользователь вознамерился вместо колеса прокрутить тягая за полоску, при клике и удержании мыши тянущаяся часть становится в 2 раза толще */
$scrollWidthClick: $scrollWidth * 2;

/* Конструктор кастомных скроллов */
@mixin customScroll($mainColor, $backgroundColor, $size) {
  overflow: auto;

  @-moz-document url-prefix('') {
    scrollbar-color: $mainColor $backgroundColor;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background: $backgroundColor;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: $mainColor;
  }
}
/* Включить скроллинг темному элементу */
@mixin scrollbarOnWhiteTheme() {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #667f8e;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $scrollThumbColorOnBlackTheme;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollThumbColorOnBlackTheme;
  }
}

/* Включить скроллинг темному элементу */
.scrollbarOnBlackTheme {
  @include customScroll($scrollThumbColorOnBlackTheme, $color_borderOnBlackTheme, $scrollWidth);
}

/*Скроллбар на светлых элементах*/
.scrollbarOnWhiteTheme {
  @include customScroll($scrollThumbColorOnLightTheme, $scrollBgColorOnLightTheme, $scrollWidth);
}

/*Скроллбар в селекте белой темы */
.scrollbarSelectWhiteTheme {
  @include customScroll($scrollThumbColorOnLightTheme, $scrollBgColorOnLightTheme, $scrollWidth);
}

/*Скроллбар в селекте темной темы */
.scrollbarSelectBlackTheme {
  @include customScroll($scrollThumbColorOnBlackTheme, $color_borderOnBlackTheme, $scrollWidth);
}

.scrollbarOnWhiteThemeNarrow {
  @include customScroll($scrollThumbColorOnLightTheme, $scrollBgColorOnLightTheme, $scrollWidth*0.5);
}
